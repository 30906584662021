'use client'
import logoInterview from '@/public/interview-mojito-logo.png'
import logoPng from '@/public/job-mojito-logo.png'
import logoBaltas from '@/public/baltas-international-logo.png'
import NextImage, { StaticImageData } from 'next/image'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'

export default function MerchantLogo({
  showLink = true,
}: {
  showLink?: boolean
}): React.JSX.Element {
  useEffect(() => {
    // receive logo from cookies or use default
    const currentFullUrl = window.location.host
    setLogo(
      currentFullUrl.includes('app.interviewmojito.com')
        ? logoInterview
        : (
          currentFullUrl.includes('big-ritefit.com')?
          logoBaltas
          :logoPng)
    )
  }, [])
  const [logo, setLogo] = useState<StaticImageData>()

  return (
    <>
      {logo &&
        (showLink ? (
          <Link href={'/'}>
            <NextImage
              className={'h-16'}
              src={logo}
              sizes="(max-width: 300px) 100vw"
              style={{ objectFit: 'contain', backgroundPositionX: 'left' }}
              alt={'Logo'}
            />
          </Link>
        ) : (
          <NextImage
            className={'h-16'}
            src={logo}
            sizes="(max-width: 300px) 100vw"
            style={{ objectFit: 'contain', backgroundPositionX: 'left' }}
            alt={'Logo'}
          />
        ))}
    </>
  )
}
